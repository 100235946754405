import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LoggedOutGuard } from './shared/guards/logged-out.guard';
import { LoginGuardGuard } from './shared/guards/login-guard.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    canActivate: [LoggedOutGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'loged',
    component: LayoutComponent,
    canActivate: [LoginGuardGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'check',
        loadChildren: () =>
          import('./pages/check-list/check-list.module').then(
            (m) => m.CheckListModule
          ),
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('./pages/forms/forms.module').then((m) => m.FormsModule),
      },
      {
        path: 'documents',
        loadChildren: () =>
          import('./pages/documents/documents.module').then(
            (m) => m.DocumentsModule
          ),
      },
      {
        path: 'invoice',
        loadChildren: () =>
          import('./pages/invoice/invoice.module').then((m) => m.InvoiceModule),
      },
      {
        path: 'questionarie',
        loadChildren: () =>
          import('./pages/ques/ques.module').then((m) => m.QuesModule),
      },
      {
        path: 'painment',
        loadChildren: () =>
          import('./pages/painment/painment.module').then(
            (m) => m.PainmentModule
          ),
      },
      {
        path: 'notes',
        loadChildren: () =>
          import('./pages/notes/notes.module').then(
            (m) => m.NotesModule
          ),
      },
      {
        path: 'uscis',
        loadChildren: () =>
          import('./pages/uscis/uscis.module').then((m) => m.UscisModule),
      },
    ],
  },
  {
    path: 'formChange',
    loadChildren: () =>
      import('./pages/form-change/form-change.module').then(
        (m) => m.FormChangeModule
      ),
  },

  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<div class="flex flex-wrap items-center justify-center h-20 sm:h-18">
  <div
    class="flex mx-4 sm:ml-5 justify-between mt-0 bg-white sm:w-full"
    style="border-radius: 10px"
  >
    <a
      class="
        text-lg
        mx-2
        flex
        justify-between
        items-center
        w-full
        bg-blue-100
        p-2
      "
      style="border-radius: 10px"
      nz-dropdown
      nzTrigger="click"
      [nzDropdownMenu]="cases"
    >
      <p class="hidden sm:block" style="font-family: 'Roboto', sans-serif">
        {{ textDefault }}
      </p>
      <p class="sm:hidden" style="font-family: 'Roboto', sans-serif">
        {{ textDefault.substr(0, 58) }}
      </p>
      <div class="text-lg flex items-center justify-center">
        <i nz-icon nzType="down" class="ml-4"></i>
      </div>
    </a>

    <nz-dropdown-menu #cases="nzDropdownMenu">
      <div class="w-auto" *ngIf="isCase">
        <ul
          class="size-drop"
          nz-menu
          nzSelectable
          style="border-radius: 10px; height: auto; word-wrap: break-word"
        >
          <div
            class="mb-0 borderElement"
            nz-menu-item
            (click)="seleccion(case.name, case.caseTypeName, case.id)"
            *ngFor="let case of casesData"
            style="font-family: 'Roboto', sans-serif"
          >
            {{ case.name }} --{{ case.caseTypeName }} --/ {{ case.contactName }}
          </div>
        </ul>
      </div>
    </nz-dropdown-menu>
  </div>
</div>

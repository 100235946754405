import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentCaseComponent } from './current-case.component';
import { AntModuleModule } from 'src/app/ant-module.module';



@NgModule({
  declarations: [
    CurrentCaseComponent
  ],
  imports: [
    CommonModule,
    AntModuleModule
  ],
  exports:[
    CurrentCaseComponent
  ]
})
export class CurrentCaseModule { }

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguajeResponse } from '../interfaces/data.interface';

@Injectable({
  providedIn: 'root',
})
export class LanguajeService {
  public languajeEmitter = new EventEmitter<LanguajeResponse>();
  constructor(private http: HttpClient) {
    this.getLanguaje();
  }
  getLanguaje() {
    let languaje = localStorage.getItem('languaje') || 'en';
    this.http
      .get<LanguajeResponse>(`../../assets/i18n/${languaje}.json`)
      .subscribe((data) => {
        this.languajeEmitter.emit(data);
      });

    return this.http.get<LanguajeResponse>(
      `../../assets/i18n/${languaje}.json`
    );
  }
}

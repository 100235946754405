export const environment = {
  production: true,
  navigationToken: localStorage.getItem('navigationToken'),
  formsUrl: 'https://app.prima.law/static/apps/form-editor/production/index.html?token=',
  uscisUrl: 'https://egov.uscis.gov/casestatus/mycasestatus.do?appReceiptNum=',
  questionniresUrl:'https://app.prima.law/static/apps/q-viewer/?token=',
  graphURL:'https://bff.prima.law/api/backend/',
  autofillApi:'https://bff.prima.law/api/autofillforms/api',
  renderUrl:'https://bff.prima.law/api/renderbot/',
  formsUrlRoute:'https://bff.prima.law/api/forms/',
  primaBff:'THBDw5xInFSAX5wDjZn9vmDGdhs1ATo4p8Z73FXzuyG6AUt7CI',
};

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginGuardGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): Observable<boolean> | boolean {
    const tokens = localStorage.getItem('navigationToken');
    if (tokens) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}

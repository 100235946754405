import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { environment } from 'src/environments/environment';

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const navigationToken = localStorage.getItem('navigationToken') || '';
  const uri = `${environment.graphURL}querys`;

  // 🔥 Middleware para agregar el token de autenticación en los headers
  const authLink = setContext(() => ({
    headers: {
      Authorization: `Bearer ${navigationToken}`,
    },
  }));

  // 🔥 Corrección: `httpLink` ya no usa `create()`, se usa directamente `new HttpLink()`
  const http = new HttpLink({ uri });

  return {
    link: ApolloLink.from([authLink, http]), // 🔥 Se usa correctamente el enlace HTTP
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  };
}

@NgModule({
  imports: [HttpClientModule], // 🔥 Asegura que `HttpClientModule` está presente
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink], // 🔥 Mantiene la dependencia necesaria
    },
  ],
})
export class GraphQLModule {}

<div class="flex items-end justify-end">
  <ul nz-menu nzMode="horizontal" class="nav-menu" style="background: transparent;">
    <li
      nz-menu-item
      [nzSelected]="checkSelected"
      [routerLink]="['/loged/check']"
      routerLinkActive="router-link-active"
      [ngClass]="{ noClick: check$ == false }"
      class="nav-menu-item-check"
    >
      <div
        class="focus:outline-none flex flex-col items-center justify-center"
        style="border: none"
      >
        <button
          [routerLink]="['/loged/check']"
          routerLinkActive="router-link-active"
          class="check w-18 h-18 rounded rounded-full p-1"
          [ngClass]="{ noCheck: !check$ || !caseSelected }"
        >
          <i
            nz-icon
            nzType="check-circle"
            nzTheme="outline"
            style="color: white"
            class="m-2 sizeIcon"
          ></i>
        </button>
        <h1
          style="font-family: 'Roboto', sans-serif"
          class="text-sm mx-auto full sm:text-lg font-bold text-center mt-2"
        >
          {{ checkListLabel }}
        </h1>
      </div>
    </li>
    <!-- <li
      nz-menu-item
      [nzSelected]="quesSelected"
      [routerLink]="['/loged/questionarie']"
      routerLinkActive="router-link-active"
      [ngClass]="{ noClick: questionnaires$ == false }"
      class="nav-menu-item-ques"
    >
      <div class="focus:outline-none flex flex-col items-center justify-center">
        <button
          class="questionnaire w-18 h-18 rounded rounded-full p-2"
          [class.noQuestionnaire]="
            questionnaires$ == false || caseSelected == false
          "
        >
          <i
            nz-icon
            nzType="unordered-list"
            nzTheme="outline"
            style="color: white"
            class="m-1 sizeIcon"
          ></i>
        </button>
        <h1
          style="font-family: 'Roboto', sans-serif"
          class="text-sm mx-auto sm:text-lg font-bold text-center mt-2"
        >
          {{ questionnaireLabel }}
        </h1>
      </div>
    </li> -->
    <li
      nz-menu-item
      [routerLink]="['/loged/uscis']"
      [nzSelected]="uscisSelected"
      routerLinkActive="router-link-active"
      [ngClass]="{ noClick: uscis$ == false }"
      class="nav-menu-item-uscis"
    >
      <div class="focus:outline-none flex flex-col items-center justify-center">
        <button
          class="uscis col-span-2 w-18 h-18 rounded rounded-full p-2"
          [class.noUscis]="uscis$ == false || caseSelected == false"
        >
          <i
            nz-icon
            nzType="file-protect"
            nzTheme="outline"
            style="color: white"
            class="m-1 sizeIcon"
          ></i>
        </button>
        <h1
          style="font-family: 'Roboto', sans-serif"
          class="text-sm mx-auto sm:text-lg font-bold text-center mt-2"
        >
          {{ uscisLabel }}
        </h1>
      </div>
    </li>
    <li
      nz-menu-item
      [nzSelected]="documentsSelected"
      [routerLink]="['/loged/documents']"
      [ngClass]="{ noClick: documents$ == false }"
      routerLinkActive="router-link-active"
      class="nav-menu-item-documents"
    >
      <div class="focus:outline-none flex flex-col items-center justify-center">
        <button
          class="documents col-span-2 w-18 h-18 rounded rounded-full p-2"
          [class.noDocuments]="documents$ == false"
          >
          <i
            nz-icon
            nzType="folder-open"
            nzTheme="outline"
            style="color: white"
            class="m-1 sizeIcon"
          ></i>
        </button>
        <h1
          style="font-family: 'Roboto', sans-serif"
          class="text-sm mx-auto sm:text-lg font-bold text-center mt-2"
        >
          {{ documentsLabel }}
        </h1>
      </div>
    </li>
    <li
      nz-menu-item
      [routerLink]="['/loged/forms']"
      routerLinkActive="router-link-active"
      [ngClass]="{ noClick: forms$ == false }"
      class="nav-menu-item-forms"
      [nzSelected]="formsSelected"
    >
      <div class="focus:outline-none flex flex-col items-center justify-center">
        <button
          class="forms col-span-2 w-18 h-18 rounded rounded-full p-2"
          [class.noForms]="forms$ == false || caseSelected == false"
        >
          <i
            nz-icon
            nzType="file-text"
            nzTheme="outline"
            style="color: white"
            class="m-1 sizeIcon"
          ></i>
        </button>
        <h1
          style="font-family: 'Roboto', sans-serif"
          class="text-sm mx-auto sm:text-lg font-bold text-center mt-2"
        >
          {{ formsLabel }}
        </h1>
      </div>
    </li>
    <li
      nz-menu-item
      routerLinkActive="router-link-active"
      (click)="switchPayment()"
      [ngClass]="{
        showPaymentClass: showPayment,
        noClick: payment$ == false && invoices$ == false
      }"
      [nzSelected]="paymentsSelected"
    >
      <div class="focus:outline-none flex flex-col items-center justify-center">
        <button
          class="payments col-span-2 w-18 h-18 rounded rounded-full p-2"
          [class.noPayments]="
            caseSelected == false || (payment$ == false && invoices$ == false)
          "
        >
          <i
            nz-icon
            nzType="dollar"
            nzTheme="outline"
            style="color: white"
            class="m-1 sizeIcon"
          ></i>
        </button>
        <h1
          style="font-family: 'Roboto', sans-serif"
          class="text-sm mx-auto sm:text-lg font-bold text-center mt-2"
        >
          {{ paymentsLabel }}
        </h1>
      </div>
    </li>
    <li
      nz-menu-item
      [nzSelected]="notesSelected"
      [routerLink]="['/loged/notes']"
      routerLinkActive="router-link-active"
      [ngClass]="{ noClick: notes$ == false }"
      class="nav-menu-item-notes"
    >
      <div class="focus:outline-none flex flex-col items-center justify-center">
        <button
          class="notes col-span-2 w-18 h-18 rounded rounded-full p-2"
          [class.noNotes]="notes$ == true"
        >
          <i
            nz-icon
            nzType="form"
            nzTheme="outline"
            style="color: white"
            class="m-1 sizeIcon"
          ></i>
        </button>
        <h1
          style="font-family: 'Roboto', sans-serif"
          class="text-sm mx-auto sm:text-lg font-bold text-center mt-2"
        >
          {{ notesLabel }}
        </h1>
      </div>
    </li>
    <!-- Opciones del Payment -->
    <li
      nz-menu-item
      routerLinkActive="router-link-active"
      (click)="switchPayment()"
      [routerLink]="['/loged/invoice']"
      [ngClass]="{
        showPaymentClass: !showPayment,
        noClick: invoices$ == false
      }"
    >
      <div class="focus:outline-none flex flex-col items-center justify-center">
        <button
          class="invoices col-span-2 w-12 h-12 rounded rounded-full"
          [class.noInvoice]="invoices$ == false || caseSelected == false"
        >
          <i
            nz-icon
            nzType="credit-card"
            nzTheme="outline"
            style="color: white; font-size: 27px"
            class="m-1"
          ></i>
        </button>
        <h1
          style="font-family: 'Roboto', sans-serif"
          class="text-sm mx-auto sm:text-lg font-bold text-center mt-2"
        >
          {{ invoiceLabel }}
        </h1>
      </div>
    </li>
    <li
      nz-menu-item
      routerLinkActive="router-link-active"
      (click)="switchPayment()"
      class="fadeIn"
      [routerLink]="['/loged/painment']"
      [ngClass]="{ showPaymentClass: !showPayment, noClick: payment$ == false }"
    >
      <div class="focus:outline-none flex flex-col items-center justify-center">
        <button
          class="payments col-span-2 w-12 h-12 rounded rounded-full"
          [class.noPaymentsPlan]="payment$ == false || caseSelected == false"
        >
          <i
            nz-icon
            nzType="container"
            nzTheme="outline"
            style="color: white; font-size: 27px"
            class="m-1"
          ></i>
        </button>
        <h1
          style="font-family: 'Roboto', sans-serif"
          class="text-sm mx-auto sm:text-lg font-bold text-center mt-2"
        >
          {{ paymentPlansLabel }}
        </h1>
      </div>
    </li>
  </ul>
</div>

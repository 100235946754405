import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CaseService } from '../services/case.service';
import { DataService } from '../services/data.service';
import { LanguajeService } from '../services/languaje.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  contact$ = this.dataService.contact$;
  contactId$ = this.dataService.contacId$;
  public logoText = '';
  public imgRoute: string = '';
  public visible: boolean;
  public visibleShort: boolean;
  public visibleLanguaje: boolean;
  public changePasswordButtonLabel: string = '';
  public logoutButtonLabel: string = '';
  public languajeButtonLabel: string = '';
  public languaje: string = '';
  public changePasswordAlertLabel: string = '';
  public okButtonAlert: string = '';
  public cancelButtonAlert: string = '';
  public sendingAlertLabel: string = '';
  public emailSendMessage: string = '';
  public errorSendingEmailLabel: string = '';
  public contactId: number;
  public firstName: string = '';
  public lastName: string = '';

  constructor(
    private dataService: DataService,
    private languajeService: LanguajeService,
    private caseService : CaseService
  ) {
    try {
      Swal.fire({
        allowOutsideClick: false,
        width: 100,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      this.dataService.dataByIdEmitter.subscribe((data) => {
        if (data) {
          Swal.close();
        }
      });
      this.dataService.case$.subscribe(data=>{
        if (data) {
          if (data.length == 0) {
            this.dataService.noDataEmitter();
            Swal.close();
          }
        }
      })
    } catch (error) {}
  }

  ngOnInit(): void {
    this.chargeLabel();
    if (localStorage.getItem('logoFirmUrl')) {
      this.imgRoute = 'https://s3-us-west-2.amazonaws.com/prima-pictures/' + localStorage.getItem('logoFirmUrl');
    } else {
      this.imgRoute = '';
    }
    // this.imgRoute = localStorage.getItem('logoFirmUrl');    
    this.languaje = localStorage.getItem('languaje');
    this.contactId$.subscribe((data)=>{
      let objData = Number(localStorage.getItem('uid').split('-')[1]);
      // if (data) {
      //   this.dataService.getContact(data);
      if (objData) {
        this.dataService.getContact(objData);
      }
    });

    this.contact$.subscribe((data: any) => { 
      if (data) {
        /* this.firstName = data.getContactById.first_name;
        this.lastName = data.getContactById.last_name; */
        this.firstName = data.getContactByIdClientPortal.first_name;
        this.lastName = data.getContactByIdClientPortal.last_name;
      }
    });
  }
  chargeLabel() {
    this.languajeService.getLanguaje().subscribe((data) => {
      this.changePasswordButtonLabel = data.Labels.changePassword;
      this.logoutButtonLabel = data.Labels.logout;
      this.languajeButtonLabel = data.Labels.languaje;
      this.changePasswordAlertLabel = data.Alerts.changePasswordMessage;
      this.okButtonAlert = data.Modals.ok;
      this.cancelButtonAlert = data.Modals.cancel;
      this.sendingAlertLabel = data.Alerts.sending;
      this.emailSendMessage = data.Alerts.emailSendMessage;
      this.errorSendingEmailLabel = data.Errors.sendingEmail;
    });
    this.languajeService.languajeEmitter.subscribe((data) => {
      this.changePasswordButtonLabel = data.Labels.changePassword;
      this.logoutButtonLabel = data.Labels.logout;
      this.languajeButtonLabel = data.Labels.languaje;
      this.changePasswordAlertLabel = data.Alerts.changePasswordMessage;
      this.okButtonAlert = data.Modals.ok;
      this.cancelButtonAlert = data.Modals.cancel;
      this.sendingAlertLabel = data.Alerts.sending;
      this.emailSendMessage = data.Alerts.emailSendMessage;
      this.errorSendingEmailLabel = data.Errors.sendingEmail;
    });
  }
  loggedOut() {
    localStorage.removeItem('navigationToken');
    localStorage.removeItem('urlImage');
    localStorage.removeItem('urlUserImage');
    localStorage.removeItem('itemSelected');
    localStorage.removeItem('firma');

    let url = localStorage.getItem('fullUrl');
    window.open(url, '_self');
  }
  changePassword() {
    Swal.fire({
      html: `<h1 style="font-size:30px; font-family: "Roboto",sans-serif; ; font-weight: bold " > ${this.changePasswordAlertLabel}  </h1>`,

      showCancelButton: true,
      confirmButtonText: `${this.okButtonAlert}`,
      cancelButtonText: `${this.cancelButtonAlert}`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          html: `<h1 style="font-size:30px;font-family: "Roboto",sans-serif; ; font-weight: bold" > ${this.sendingAlertLabel}.</h1>`,
          allowOutsideClick: false,

          didOpen: () => {
            Swal.showLoading();
          },
        });

        this.dataService.changePassword().subscribe(
          (res: any) => {
            let email = res.data.changePasswordClientPortal.email;

            if (email) {
              Swal.close();
              Swal.fire({
                html: `<h1 style="font-size:30px;font-family: "Roboto",sans-serif; ; font-weight: bold" > ${this.emailSendMessage} </h1> `,
                confirmButtonText: `${this.okButtonAlert}`,
                cancelButtonText: `${this.cancelButtonAlert}`,
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.close();
                } else if (result.isDenied) {
                  Swal.close();
                }
              });
            }
          },
          (err) => {
            Swal.close();
            Swal.fire({
              title: `${this.errorSendingEmailLabel}`,
              confirmButtonText: `${this.okButtonAlert}`,
              cancelButtonText: `${this.cancelButtonAlert}`,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.close();
              } else if (result.isDenied) {
                Swal.close();
              }
            });
          }
        );
      } else if (result.isDismissed) {
      }
    });
  }

  selectLanguaje(lang: string) {
    this.visibleLanguaje = false;
    switch (lang) {
      case 'en':
        this.languaje = 'en';
        localStorage.setItem('languaje', 'en');
        this.languajeService.getLanguaje();
        break;
      case 'es':
        this.languaje = 'es';
        localStorage.setItem('languaje', 'es');
        this.languajeService.getLanguaje();
        break;
      case 'russian':
        this.languaje = 'russian';
        localStorage.setItem('languaje', 'russian');
        this.languajeService.getLanguaje();
        break;
      case 'portuguese':
        this.languaje = 'portuguese';
        localStorage.setItem('languaje', 'portuguese');
        this.languajeService.getLanguaje();
        break;
      case 'arabic':
        this.languaje = 'arabic';
        localStorage.setItem('languaje', 'arabic');
        this.languajeService.getLanguaje();
        break;
      case 'armenio':
        this.languaje = 'armenio';
        localStorage.setItem('languaje', 'armenio');
        this.languajeService.getLanguaje();
        break;
      case 'cantonese':
        this.languaje = 'cantonese';
        localStorage.setItem('languaje', 'cantonese');
        this.languajeService.getLanguaje();
        break;
      case 'chinese':
        this.languaje = 'chinese';
        localStorage.setItem('languaje', 'chinese');
        this.languajeService.getLanguaje();
        break;
      case 'creole':
        this.languaje = 'creole';
        localStorage.setItem('languaje', 'creole');
        this.languajeService.getLanguaje();
        break;
      case 'french':
        this.languaje = 'french';
        localStorage.setItem('languaje', 'french');
        this.languajeService.getLanguaje();
        break;
      case 'german':
        this.languaje = 'german';
        localStorage.setItem('languaje', 'german');
        this.languajeService.getLanguaje();
        break;
      case 'hindu':
        this.languaje = 'hindu';
        localStorage.setItem('languaje', 'hindu');
        this.languajeService.getLanguaje();
        break;
      case 'italian':
        this.languaje = 'italian';
        localStorage.setItem('languaje', 'italian');
        this.languajeService.getLanguaje();
        break;
      case 'japanese':
        this.languaje = 'japanese';
        localStorage.setItem('languaje', 'japanese');
        this.languajeService.getLanguaje();
        break;
      case 'korean':
        this.languaje = 'korean';
        localStorage.setItem('languaje', 'korean');
        this.languajeService.getLanguaje();
        break;
      case 'turkey':
        this.languaje = 'turkey';
        localStorage.setItem('languaje', 'turkey');
        this.languajeService.getLanguaje();
        break;
      case 'ucraniano':
        this.languaje = 'ucraniano';
        localStorage.setItem('languaje', 'ucraniano');
        this.languajeService.getLanguaje();
        break;

      default:
        break;
    }
  }
}

import { Injectable, EventEmitter } from '@angular/core';

import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {
  Bills,
  Case,
  CaseChecklists,
  CasesForms,
  DataResponse,
  Files,
  PaymentPlans,
  Questionarie,
  Uscis,
  Notes,
  Contact
} from '../interfaces/data.interface';
import { environment } from 'src/environments/environment';

const cases = gql`
  {
    cases {
      id
      name
      contactId
      caseTypeName
      createdAt
    }
  }
`;

const casesByCompany = gql`  
  query getDataById($contactId: Int, $isRepresentative: Boolean) {
    casesByCompanyOrLegalRepresentant(contactId:$contactId, isRepresentative: $isRepresentative) {
      id
      name
      contactId
      caseTypeName
      createdAt
      contactName
    }
  }  
`;
const getBill = gql`
  query billPrint($_id: String) {
    billPrint(_id: $_id, pdfBase64: true) {
      pdfBase64
      issueDate
      contact {
        firstName
      }
    }
  }
`;

const getDataById = gql`
  query getDataById($caseId: Int) {
    caseChecklists(caseId: $caseId) {
      id
      caseId
      completed
      name
      priority
    }
    caseQuestionnaires(caseId: $caseId) {
      _id
      caseId
      contactId
      createdAt

      contact {
        firstName
        lastName
      }
    }
    casesForms(caseId: $caseId) {
      _id
      caseId
      formName
      formTitle
      createdAt
      status
      clientPortal
    }
    bills(caseId: $caseId) {
      _id
      status
      invoiceTotal
      issueDate
      planId
      dueDate
      caseId
      billIdLexCharge
    }
    paymentPlansClientPortal(caseId: $caseId) {
      description
      caseId
      bills {
        planId
        _id
        status
        trust
        invoiceTotal
        issueDate
        billIdLexCharge
      }
    }
    files(caseId: $caseId) {
      id
      originalName
      name
      caseId
      checklistId
      storageName
    }
    uscisClientPortal(caseId: $caseId) {
      id
      caseId
      recipient
      label
      description
      formName
      formTitle
      lastStatus
    }
    notes(search: {visibleOnClientPortal: "on", portal: 0}, caseId: $caseId, print: false) {
      _id
      body
      createdAt
      updatedAt
      userId
      caseId
      contactId
      subject
      regardingId
      regardingType
      date
      visibleOnClientPortal
    }
  }
`;

const contact = gql`
  query getDataById($contactId: Int) {
    getContactByIdClientPortal(id:$contactId){
      id
      picture
      full_name
      first_name
      last_name
      middle_name
      email
      company_name
      is_company
      is_company_representative
    }
  }
`;

const files = gql`
  query files($id: Int) {
    files(id: $id, download: true) {
      id
      name
      file
    }
  }
`;
const uploadFile = gql`
  mutation addFile(
    $nameFile: String!
    $file: String!
    $caseId: Int!
    $contactId: Int!
    $checklistId: Int
  ) {
    fileAdd(
      nameFile: $nameFile
      file: $file
      caseId: $caseId
      contactId: $contactId
      checklistId: $checklistId
      uploadBy: "client"
    ) {
      id
      caseId
    }
  }
`;
const renameFile = gql`
  mutation changeName($id: Int!, $name: String!) {
    fileEdit(id: $id, update: { name: $name }) {
      caseId
    }
  }
`;
const chagePassword = gql`
  mutation changePasswordClientPortal {
    changePasswordClientPortal {
      email
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DataService {
  error: any;

  contactIdSubject = new BehaviorSubject<any>(null);
  contacId$ = this.contactIdSubject.asObservable();

  caseIdSubject = new BehaviorSubject<any>(null);
  caseId$ = this.caseIdSubject.asObservable();

  checkListSubject = new BehaviorSubject<CaseChecklists[]>(null);
  checkList$ = this.checkListSubject.asObservable();

  casesSubject = new BehaviorSubject<Case[]>(null);
  case$ = this.casesSubject.asObservable();
  
  questionarieSubject = new BehaviorSubject<Questionarie[]>(null);
  questionarie$ = this.questionarieSubject.asObservable();
  
  casesFormsSubject = new BehaviorSubject<CasesForms[]>(null);
  casesForms$ = this.casesFormsSubject.asObservable();
  
  billsSubject = new BehaviorSubject<Bills[]>(null);
  bills$ = this.billsSubject.asObservable();
  
  paymentPlanSubject = new BehaviorSubject<PaymentPlans[]>(null);
  paymentPlan = this.paymentPlanSubject.asObservable();

  filesSubject = new BehaviorSubject<Files[]>(null);
  files$ = this.filesSubject.asObservable();

  uscisSubject = new BehaviorSubject<Uscis[]>(null);
  uscis$ = this.uscisSubject.asObservable();

  dowloadFileSubject = new BehaviorSubject<any>(null);
  downloadFile$ = this.dowloadFileSubject.asObservable();

  uploadSubject = new BehaviorSubject<any>(null);
  upload$ = this.uploadSubject.asObservable();

  fileBaseSubject = new BehaviorSubject<any>(null);
  fileBase$ = this.fileBaseSubject.asObservable();
  
  noHasDataSubject = new BehaviorSubject<any>(null);
  noHasData$ = this.noHasDataSubject.asObservable();
  
  notesSubject = new BehaviorSubject<Notes[]>(null);
  notes$ = this.notesSubject.asObservable();
  
  contactSubject = new BehaviorSubject<Contact>(null);
  contact$ = this.contactSubject.asObservable();

  casesByCompanySubject = new BehaviorSubject<Case[]>(null);
  casesByCompany$ = this.casesByCompanySubject.asObservable();
  
  public dataCheckEmmiter = new EventEmitter<any>();
  public invoiceEmitter = new EventEmitter<any>();
  public dataByIdEmitter = new EventEmitter<any>();

  constructor(private apollo: Apollo) {
    try {
      this.getCases();
      localStorage.removeItem('Case');
    } catch (error) {
      console.log('error obteniendo los datos');
    }
  }
  getCases() {
    try {
      this.apollo
        .watchQuery<any>({
          query: cases,
          errorPolicy: 'all',
          context: {
            headers: {
              'X-Access-Key-Bff': environment.primaBff,
            },
          }
        })
        .valueChanges.pipe(
          catchError((err) => {
            if (err.networkError.status === 401) {
              localStorage.removeItem('navigationToken');
              localStorage.removeItem('urlImage');
              localStorage.removeItem('urlUserImage');

              localStorage.removeItem('firma');

              let url = localStorage.getItem('urlFull');

              window.open(url, '_self');
            }

            return throwError(err.graphQLErrors);
          }),
          tap(({ data }) => {
            this.casesSubject.next(data.cases || []);

            if (data.cases !== null) {
              if (data.cases[0] !== undefined) {
                this.contactIdSubject.next(data.cases[0].contactId);
              }
            }
          })
        )
        .subscribe();
    } catch (error) {
      console.log('hubo un error', error);
    }
  }
  async getFileBase(file: string) {
    this.fileBaseSubject.next(file);
  }

  getDataById(caseId: number) {
    try {
      return this.apollo
        .watchQuery<DataResponse>({
          query: getDataById,
          variables: { caseId },
          errorPolicy: 'ignore',
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              'X-Access-Key-Bff': environment.primaBff,
            },
          }
        })
        .valueChanges.subscribe((datar) => {
          const data: DataResponse = datar.data;
          
          this.caseIdSubject.next(caseId || []);
          this.checkListSubject.next(data.caseChecklists || []);
          this.questionarieSubject.next(data.caseQuestionnaires || []);
          this.uscisSubject.next(data.uscisClientPortal || []);
          this.casesFormsSubject.next(data.casesForms || []);
          this.billsSubject.next(data.bills || []);
          this.paymentPlanSubject.next(data.paymentPlansClientPortal || []);
          this.filesSubject.next(data.files || []);
          this.notesSubject.next(data.notes || []);
          this.dataByIdEmitter.emit(data);

        });
    } catch (error) {
      console.log(error);
    }
  }
  noDataEmitter() {
    this.caseIdSubject.next([]);
    this.checkListSubject.next([]);
    this.questionarieSubject.next([]);
    this.uscisSubject.next([]);
    this.casesFormsSubject.next([]);
    this.billsSubject.next([]);
    this.paymentPlanSubject.next([]);
    this.filesSubject.next([]);
  }

  getBill(_id: string) {
    try {
      this.apollo
        .watchQuery({
          query: getBill,
          variables: { _id },
          context: {
            headers: {
              'X-Access-Key-Bff': environment.primaBff,
            },
          }
        })
        .valueChanges.subscribe((result: any) => {
          this.invoiceEmitter.emit(result);
          var a = document.createElement('a');
          let baseData = result.data.billPrint[0].pdfBase64;
          let date = result.data.billPrint[0].issueDate;
          a.href = 'data:image/pdf;base64,' + baseData;
          a.download = `invoice-${date}.pdf`;
          a.click();
        });
    } catch (error) {
      console.log('error en bills', error);
    }
  }
  uploadFile(nameFile, file, caseId, contactId, checklistId) {
    try {
      return this.apollo.mutate({
        mutation: uploadFile,
        variables: { nameFile, file, caseId, contactId, checklistId },
        fetchPolicy: 'no-cache',
        context: {
          headers: {
            'X-Access-Key-Bff': environment.primaBff,
          },
        }
      });
    } catch (error) {
      console.log('hubo un error cargando el archivo: ', error);
    }
  }
  uploadDocument(nameFile, file, caseId, contactId) {
    try {
      return this.apollo.mutate({
        mutation: uploadFile,
        variables: { nameFile, file, caseId, contactId },
        context: {
          headers: {
            'X-Access-Key-Bff': environment.primaBff,
          },
        }
      });
    } catch (error) {
      console.log('hubo un error cargando el archivo: ', error);
    }
  }
  changeName(id: number, name: string) {
    try {
      return this.apollo.mutate({
        mutation: renameFile,
        variables: { id, name },
        context: {
          headers: {
            'X-Access-Key-Bff': environment.primaBff,
          },
        }
      });
    } catch (error) {
      console.log('Error Rename File');
    }
  }
  changePassword() {
    try {
      return this.apollo.mutate({
        mutation: chagePassword,
        context: {
          headers: {
            'X-Access-Key-Bff': environment.primaBff,
          },
        }
      });
    } catch (error) {
      console.log('hubo un error: ', error);
    }
  }
  getFile(id: number) {
    try {
      return this.apollo
        .watchQuery({
          query: files,
          variables: { id },
          errorPolicy: 'ignore',
          context: {
            headers: {
              'X-Access-Key-Bff': environment.primaBff,
            },
          }
        })
        .valueChanges.subscribe((result: any) => {
          this.dataCheckEmmiter.emit(result);
          var a = document.createElement('a');
          a.href = 'data:image/png;base64,' + result.data.files[0].file;
          a.download = result.data.files[0].name;
          a.click();
        });
    } catch (error) {
      console.log('Error en file', error);
    }
  }
  getContact(contactId: number) {  
    try {
      return this.apollo
        .watchQuery<Contact>({
          query: contact,
          variables: { contactId },
          errorPolicy: 'ignore',
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              'X-Access-Key-Bff': environment.primaBff,
            },
          }
        }).valueChanges.subscribe((datar)=>{
          const data: Contact = datar.data;
          this.contactSubject.next(data);
        });
    } catch (error) {
      console.log('hubo un error', error);
    }
  }

  getCasesByCompany(contactId:number, isRepresentative:boolean) {
    try {
      this.apollo
        .watchQuery<any>({
          query: casesByCompany,          
          variables: { contactId, isRepresentative },
          errorPolicy: 'all',
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              'X-Access-Key-Bff': environment.primaBff,
            },
          }
        })
        .valueChanges.pipe(
          catchError((err) => {
            if (err.networkError.status === 401) {
              localStorage.removeItem('navigationToken');
              localStorage.removeItem('urlImage');
              localStorage.removeItem('urlUserImage');

              localStorage.removeItem('firma');

              let url = localStorage.getItem('urlFull');

              window.open(url, '_self');
            }

            return throwError(err.graphQLErrors);
          }),
          tap(({ data }) => {            
            this.casesByCompanySubject.next(data.casesByCompanyOrLegalRepresentant || []);

            if (data.casesByCompanyOrLegalRepresentant !== null) {
              if (data.casesByCompanyOrLegalRepresentant[0] !== undefined) {
                this.contactIdSubject.next(contactId);
              }
            }
          })
        )
        .subscribe();
    } catch (error) {
      console.log('hubo un error', error);
    }
  }
}

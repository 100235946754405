import { NgModule, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { GraphQLModule } from './graphql.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { es_ES } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AntModuleModule } from './ant-module.module';
import { HeaderModule } from './shared/header/header.module';
import { CurrentCaseModule } from './shared/current-case/current-case.module';
import { LayoutModule } from './layout/layout.module';
import { appConfig } from './app.config'; // 🔥 Importamos la configuración de Apollo

registerLocaleData(es);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    GraphQLModule, 
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    AntModuleModule,
    HeaderModule,
    CurrentCaseModule,
    LayoutModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: es_ES },
    ...appConfig.providers, // 🔥 Inyectamos la configuración de Apollo aquí
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { AntModuleModule } from 'src/app/ant-module.module';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { RouterModule } from '@angular/router';
import { CurrentCaseModule } from '../current-case/current-case.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    AntModuleModule,
    NzMenuModule,
    RouterModule,
    CurrentCaseModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { CaseService } from '../services/case.service';
import { LanguajeService } from '../services/languaje.service';
import { LanguajeResponse, MenuButtons } from '../interfaces/data.interface';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  public check$: boolean;
  public forms$: boolean;
  public questionnaires$: boolean;
  public uscis$: boolean;
  public invoices$: boolean;
  public payment$: boolean;
  public notes$: boolean;
  public documents$:boolean=true;
  public caseSelected: boolean = false;
  public toPay$: boolean;
  public topCenter: string = 'topCenter';
  public showPayment: boolean = false;
  public showNotes: boolean = false;
  public checkSelected: boolean = false;
  public quesSelected: boolean = false;
  public uscisSelected: boolean = false;
  public documentsSelected: boolean = false;
  public formsSelected: boolean = false;
  public paymentsSelected: boolean = false;
  public notesSelected: boolean = false;
  public menuButtons: MenuButtons;
  public checkListLabel: string = '';
  public questionnaireLabel: string = '';
  public uscisLabel: string = '';
  public documentsLabel: string = '';
  public formsLabel: string = '';
  public paymentsLabel: string = '';
  public notesLabel: string = '';
  public invoiceLabel: string = '';
  public paymentPlansLabel: string = '';

  constructor(
    private dataService: DataService,
    private caseService: CaseService,
    private languajeService: LanguajeService
  ) {}

  ngOnInit(): void {
    this.chargeLabels();
    this.itemSelected();

    // Activación de botones
    this.dataService.caseIdSubject.subscribe((data) => {
      if (data) {
        this.caseSelected = true;
      }
    });
    this.itemsVerification();
  }
  chargeLabels() {
    this.languajeService.getLanguaje().subscribe((data) => {
      this.languajeService.languajeEmitter.subscribe((data) => {
        const { MenuButtons } = data;
        this.checkListLabel = MenuButtons.checkList;
        this.questionnaireLabel = MenuButtons.questionnaires;
        this.uscisLabel = MenuButtons.uscis;
        this.documentsLabel = MenuButtons.documents;
        this.formsLabel = MenuButtons.forms;
        this.paymentsLabel = MenuButtons.payments;
        this.notesLabel = MenuButtons.notes;
        this.invoiceLabel = MenuButtons.invoices;
        this.paymentPlansLabel = MenuButtons.paymentPlant;
      });
      const { MenuButtons } = data;
      this.checkListLabel = MenuButtons.checkList;
      this.questionnaireLabel = MenuButtons.questionnaires;
      this.uscisLabel = MenuButtons.uscis;
      this.documentsLabel = MenuButtons.documents;
      this.formsLabel = MenuButtons.forms;
      this.paymentsLabel = MenuButtons.payments;
      this.notesLabel = MenuButtons.notes;
      this.invoiceLabel = MenuButtons.invoices;
      this.paymentPlansLabel = MenuButtons.paymentPlant;
    });
  }

  switchPayment() {
    this.showPayment = !this.showPayment;
  }
  itemsVerification() {
    this.dataService.case$.subscribe(data=>{
      if (data) {
        if (data.length == 0) {
          this.dataService.noDataEmitter();
          //this.documents$ = false;
        }
      }
    });
    this.dataService.checkListSubject.subscribe((data) => {
      if (data) {
        if (data.length > 0) {
          this.check$ = true;
        } else {
          this.check$ = false;
        }
      }
    });
    this.dataService.casesFormsSubject.subscribe((data) => {
      if (data) {
        if (data.length > 0) {
          this.forms$ = true;
        } else {
          this.forms$ = false;
        }
      }
    });
    this.dataService.questionarieSubject.subscribe((data) => {
      if (data) {
        if (data.length > 0) {
          this.questionnaires$ = true;
        } else {
          this.questionnaires$ = false;
        }
      }
    });
    this.dataService.uscisSubject.subscribe((data) => {
      if (data) {
        if (data.length > 0) {
          this.uscis$ = true;
        } else {
          this.uscis$ = false;
        }
      }
    });
    this.dataService.billsSubject.subscribe((data) => {
      if (data) {
        if (data.length > 0) {
          this.invoices$ = true;
        } else {
          this.invoices$ = false;
        }
      }
    });
    this.dataService.paymentPlanSubject.subscribe((data) => {
      if (data) {
        if (data.length > 0) {
          this.payment$ = true;
        } else {
          this.payment$ = false;
        }
      }
    });
    this.dataService.notesSubject.subscribe((data) => {
      if (data) {
        if (data.length > 0) {
          this.notes$ = true;
        } else {
          this.notes$ = false;
        }
      }
    });
    this.dataService.filesSubject.subscribe((data) => {
      if (data) {
        let dataFilter = data.filter((data) => data.checklistId == null);
        if (dataFilter.length > 0) {          
          this.documents$ = true;
        } else {       
          this.documents$ = false;
        }
      }
    });
  }
  itemSelected() {
    this.caseService.itemSelectedEmitter.subscribe((data) => {
      switch (data) {
        case 'checkList':
          this.resetItemSelected();
          this.checkSelected = true;

          break;
        case 'questionnaires':
          this.resetItemSelected();
          this.quesSelected = true;

          break;
        case 'uscis':
          this.resetItemSelected();
          this.uscisSelected = true;

          break;
        case 'documents':
          this.resetItemSelected();
          this.documentsSelected = true;

          break;
        case 'forms':
          this.resetItemSelected();
          this.formsSelected = true;

          break;
        case 'payments':
          this.resetItemSelected();
          this.paymentsSelected = true;

          break;
        case 'notes':
          this.resetItemSelected();
          this.notesSelected = true;

          break;
        case 'invoices':
          this.resetItemSelected();
          this.paymentsSelected = true;

          break;

        default:
          break;
      }
    });
  }
  resetItemSelected() {
    this.quesSelected = false;
    this.checkSelected = false;
    this.uscisSelected = false;
    this.documentsSelected = false;
    this.formsSelected = false;
    this.paymentsSelected = false;
    this.notesSelected = false;
  }
}

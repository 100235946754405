import { Injectable, OnInit, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class CaseService implements OnInit {
  cases$ = this.dataService.case$;
  caseSelectedSubject = new BehaviorSubject<string>(null);
  caseSelected$ = this.caseSelectedSubject.asObservable();
  itemSelectedSubject = new BehaviorSubject<string>(null);
  itemSelected$ = this.itemSelectedSubject.asObservable();
  itemSelectedEmitter = new EventEmitter();
  constructor(private dataService: DataService) {
    this.itemSelected();
    if (!localStorage.getItem('Case')) {
      this.cases$.subscribe((data) => {
        if (data) {
          if (data.length > 0) {
            if (!localStorage.getItem('Case')) {
              let arrayFechas = data.map((cases) => new Date(cases.createdAt));
              var max = new Date(Math.max.apply(null, arrayFechas));
              data.forEach((element) => {
                if (new Date(element.createdAt).toString() === max.toString()) {
                  this.caseSelectedSubject.next(
                    element.name + element.caseTypeName
                  );
                  this.dataService.getDataById(element.id);
                  localStorage.setItem(
                    'Case',
                    element.name + '-' + element.caseTypeName
                  );
                  return;
                }
              });
            }
          } else {
            this.caseSelectedSubject.next('No shared cases');
            return;
          }
        }
      });
    }
  }
  ngOnInit(): void {}

  selectedCase(name?: string) {
    if (!name) {
      this.cases$.subscribe((data) => {
        if (data === null) {
          this.caseSelectedSubject.next('No shared cases');
          return;
        }
        if (localStorage.getItem('Case') === null) {
          this.caseSelectedSubject.next('No shared cases');
          return;
        } else {
          this.caseSelectedSubject.next(localStorage.getItem('Case'));
        }
      });
    } else {
      this.caseSelectedSubject.next(name);
    }
  }
  itemSelected() {
    let item = localStorage.getItem('itemSelected') || 'checkList';

    switch (item) {
      case 'checkList':
        this.itemSelectedEmitter.emit('checkList');

        break;
      case 'questionnaires':
        this.itemSelectedEmitter.emit('questionnaires');
        break;
      case 'uscis':
        this.itemSelectedEmitter.emit('uscis');
        break;
      case 'documents':
        this.itemSelectedEmitter.emit('documents');
        break;
      case 'forms':
        this.itemSelectedEmitter.emit('forms');
        break;
      case 'payments':
        this.itemSelectedEmitter.emit('payments');
        break;
      case 'invoices':
        this.itemSelectedEmitter.emit('payments');
        break;

      default:
        break;
    }
  }
}

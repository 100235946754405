import { ApplicationConfig, inject } from '@angular/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from 'src/environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptors([])), // 🔥 Agrega interceptores si los necesitas
    provideApollo(() => {
      const httpLink = inject(HttpLink);
      const navigationToken = localStorage.getItem('navigationToken') || '';
      
      return {
        link: httpLink.create({ uri: `${environment.graphURL}querys?token=${navigationToken}` }),
        cache: new InMemoryCache(),
      };
    }),
  ],
};

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoggedOutGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): Observable<boolean> | boolean {
    let url = window.location.toString();
    const token = localStorage.getItem('navigationToken');
    const Url = new URL(window.location.href);
    const redirect = Url.searchParams.get('redirect');
    const newUid = Url.searchParams.get('uid');
    const oldUid = localStorage.getItem('uid');

    if (token) {
      if (newUid) {
        if (newUid == oldUid) {
        } else {
          localStorage.removeItem('navigationToken');
          localStorage.setItem('fullUrl', url );
        }
      }
      let redirectComponent = '';
      switch (redirect) {
        case 'File':
          redirectComponent = 'documents'
          break;

        case 'Form':
          redirectComponent = 'forms'
          break;

        case 'Bill':
          redirectComponent = 'invoice'
          break;

        case 'Payment Plan':
          redirectComponent = 'painment'
          break;

        default:
          redirectComponent = 'check'
          break;
      }

      this.router.navigate([`/loged/${redirectComponent}`]);
    } else {
      return true;
    }
  }
}

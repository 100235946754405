import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { CaseService } from '../services/case.service';
import Swal from 'sweetalert2';
import { Case } from '../interfaces/data.interface';

@Component({
  selector: 'app-current-case',
  templateUrl: './current-case.component.html',
  styleUrls: ['./current-case.component.css'],
})
export class CurrentCaseComponent implements OnInit {
  cases$ = this.dataService.case$;
  contact$ = this.dataService.contact$;
  casesByCompany$ = this.dataService.casesByCompany$;
  textDefault: string;
  public isCase: boolean;
  public casesData:Case[];

  constructor(
    private dataService: DataService,
    private caseService: CaseService
  ) {}

  ngOnInit(): void {
    let objData = Number(localStorage.getItem('uid').split('-')[1]);
    if (objData) {
      this.dataService.getContact(objData);
    }
    this.contact$.subscribe((data: any) => {
      if (data) {
        localStorage.setItem('isRepresentative', data.getContactByIdClientPortal.is_company_representative);   
        localStorage.setItem('isCompany', data.getContactByIdClientPortal.is_company);   
      } 
    });

    if (localStorage.getItem('isRepresentative') !== null || localStorage.getItem('isCompany') !== null) {
      
      if (localStorage.getItem('isCompany') == "on" || localStorage.getItem('isRepresentative') == 'true') {
        let flagRepresentative = localStorage.getItem('isRepresentative') == 'true' ? true : false;
        this.dataService.getCasesByCompany(objData, flagRepresentative);
        this.casesByCompany$.subscribe((data) => {
          if (data) {              
            if (data.length > 0) {
              this.isCase = true;
              this.casesData  = data;
            } else {
              this.isCase = false;
            }
          }
        });
      } else {
        this.cases$.subscribe((data) => {
          if (data) {
            if (data.length > 0) {
              this.isCase = true;
              this.casesData  = data;
            } else {
              this.isCase = false;
            }
          }
        });
      }
    }

    if (localStorage.getItem('Case') === null) {
      this.caseService.selectedCase();
      this.caseService.caseSelectedSubject.subscribe((data) => {
        this.textDefault = data;
      });
    } else if (localStorage.getItem('Case') !== null) {
      this.textDefault = localStorage.getItem('Case');
    }
  }

  seleccion(name: string, casetype: string, caseId: number): void {
    Swal.fire({
      allowOutsideClick: false,
      width: 100,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let fullName = name + casetype;
    

    localStorage.setItem('Case', fullName);
    this.caseService.selectedCase(fullName);
    this.dataService.getDataById(caseId);
    this.dataService.dataByIdEmitter.subscribe((data) => {
      if (data) {
        Swal.close();
      }
    });
  }
}

<div class="grid grid-cols-5 mx-2 flex items-center showLarge">
  <div class="bg-white col-span-1">
    <div class="flex flex-wrap items-center justify-center">
      <img
        class="mx-auto my-2"
        [src]="imgRoute"
        alt=""
        style="
          max-width: 400px;
          width: 100%;
          box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
          border-radius: 10px;
        "
      />
    </div>
  </div>
  <div class="col-span-3">
    <div class="container-contact-name">
      <h1 class="page-title">
      Welcome, {{firstName}} {{lastName}}
      </h1>
    </div>
    <app-current-case></app-current-case>
  </div>
  <div class="col-span-1 my-2">
    <div class="flex justify-end">
      <button
        nz-popover
        [(nzPopoverVisible)]="visible"
        nzPopoverTrigger="click"
        [nzPopoverContent]="contentTemplate"
      >
        <i
          nz-icon
          nzType="setting"
          nzTheme="outline"
          style="font-size: 40px"
        ></i>
      </button>
      <ng-template #contentTemplate style="border-radius: 10px">
        <div class="flex flex-col">
          <div
            class="flex flex-wrap"
            type="button"
            (click)="changePassword()"
            style="cursor: pointer"
          >
            <h1>{{ changePasswordButtonLabel }}</h1>
            <i
              nz-icon
              nzType="tool"
              nzTheme="outline"
              style="font-size: 20px"
              class="ml-4"
            ></i>
          </div>

          <hr class="my-2" />
          <div>
            <button
              nz-popover
              [(nzPopoverVisible)]="visibleLanguaje"
              nzPopoverTrigger="click"
              [nzPopoverContent]="contentTemplate"
              nzPopoverPlacement="left"
              class="flex flex-column justify-between w-full"
            >
              <h1>{{ languajeButtonLabel }}</h1>
              <i
                nz-icon
                class="ml-4"
                style="font-size: 20px"
                nzType="translation"
                nzTheme="outline"
              ></i>
            </button>
            <ng-template #contentTemplate>
              <div class="flex flex-col w-full">
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'arabic' }"
                  (click)="selectLanguaje('arabic')"
                >
                  Arabic
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'armenio' }"
                  (click)="selectLanguaje('armenio')"
                >
                  Armenio
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'cantonese' }"
                  (click)="selectLanguaje('cantonese')"
                >
                  Cantonese
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'chinese' }"
                  (click)="selectLanguaje('chinese')"
                >
                  Chinese
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'creole' }"
                  (click)="selectLanguaje('creole')"
                >
                  Creole
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'en' }"
                  (click)="selectLanguaje('en')"
                >
                  English
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'es' }"
                  (click)="selectLanguaje('es')"
                >
                  Español
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'french' }"
                  (click)="selectLanguaje('french')"
                >
                  French
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'german' }"
                  (click)="selectLanguaje('german')"
                >
                  German
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'hindu' }"
                  (click)="selectLanguaje('hindu')"
                >
                  Hindú
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'italian' }"
                  (click)="selectLanguaje('italian')"
                >
                  Italian
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'japanese' }"
                  (click)="selectLanguaje('japanese')"
                >
                  Japanese
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'korean' }"
                  (click)="selectLanguaje('korean')"
                >
                  Korean
                </button>

                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'russian' }"
                  (click)="selectLanguaje('russian')"
                >
                  Russian
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'portuguese' }"
                  (click)="selectLanguaje('portuguese')"
                >
                  Portuguese
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'turkey' }"
                  (click)="selectLanguaje('turkey')"
                >
                  Turkey
                </button>
                <button
                  [ngClass]="{ 'languaje-selected': languaje == 'ucraniano' }"
                  (click)="selectLanguaje('ucraniano')"
                >
                  Ukranian
                </button>
              </div>
            </ng-template>
          </div>
          <hr class="my-2" />
          <div
            type="button"
            (click)="loggedOut()"
            style="cursor: pointer"
            class="flex flex-wrap justify-between"
          >
            <h1>{{ logoutButtonLabel }}</h1>
            <i
              nz-icon
              nzType="poweroff"
              nzTheme="outline"
              style="font-size: 20px"
              class="ml-4"
            ></i>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<div class="grid grid-cols-6 mx-2 flex items-center showShort">
  <div class="bg-white col-span-3 p-2">
    <div class="flex flex-wrap items-center justify-center">
      <img
        class="mx-auto w-64 sm:w-96 my-2"
        [src]="imgRoute"
        alt=""
        style="
          max-width: 400px;
          width: 100%;
          box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
          border-radius: 10px;
        "
      />
    </div>
  </div>

  <div class="col-span-3 my-2">
    <div class="flex justify-end">
      <button
        nz-popover
        [(nzPopoverVisible)]="visibleShort"
        nzPopoverTrigger="click"
        [nzPopoverContent]="contentTemplate"
      >
        <i
          nz-icon
          nzType="setting"
          nzTheme="outline"
          style="font-size: 40px"
        ></i>
      </button>
      <ng-template #contentTemplate>
        <div
          class="flex flex-col"
          type="button"
          (click)="changePassword()"
          style="cursor: pointer"
        >
          <div class="flex flex-wrap">
            <h1>{{ changePasswordButtonLabel }}</h1>
            <i
              nz-icon
              nzType="tool"
              nzTheme="outline"
              style="font-size: 20px"
              class="ml-4"
            ></i>
          </div>
          <hr class="my-2" />
          <div
            type="button"
            (click)="loggedOut()"
            style="cursor: pointer"
            class="flex flex-wrap justify-between"
          >
            <h1>{{ logoutButtonLabel }}</h1>
            <i
              nz-icon
              nzType="poweroff"
              nzTheme="outline"
              style="font-size: 20px"
              class="ml-4"
            ></i>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="col-span-6">
    <app-current-case></app-current-case>
  </div>
</div>
